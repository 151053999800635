<template>
  <div class="no-data text-center m-auto">
    <img v-if="type === 'question'" src="~/mlearn/icons/no-question.svg" class="icon" alt="no data" />
    <img v-else src="~/mlearn/icons/no-data.svg" class="icon" alt="no data" />
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Boolean,
      default () {
        return true
      }
    },
    type: {
      type: String,
      default () {
        return 'data'
      }
    },
    text: {
      type: String,
      default () {
        return 'Không có dữ liệu'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-data{
    padding:130px 0;
    @media (max-width:576px) {
      padding:50px 0;
    }
    .text{
      font-weight: 500;
      color: #65676B;
      font-size: 18px;
      @media (max-width:576px) {
        font-size: 15px;
      }
    }
  }
</style>
