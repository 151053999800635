<template>
  <div class="d-flex list-share">
    <div @click="openNewWindow('twitter')"><i class="fa fa-twitter" aria-hidden="true" title="Share with twitter"></i></div>
    <div @click="openNewWindow('facebook')"><i class="fa fa-facebook" aria-hidden="true" title="Share with facebook"></i></div>
    <div @click="openNewWindow('telegram')"><i class="fa fa-telegram" aria-hidden="true" title="Share with telegram"></i></div>
    <div class="zalo-share-button" data-href="" :data-oaid="OA_ID" data-layout="2" data-color="blue" data-customize="false" title="Share with Zalo"></div>
    <script src="https://sp.zalo.me/plugins/sdk.js"></script>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default () {
        return 'Mlearn'
      }
    },
    link: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      FB_ID: process.env.NUXT_ENV_FACEBOOK_ID,
      OA_ID: process.env.NUXT_ENV_ZALO_OA_ID
    }
  },
  // head () {
  //   return {
  //     script: [
  //       { type: 'text/javascript', src: 'https://sp.zalo.me/plugins/sdk.js' }
  //     ]
  //   }
  // },
  methods: {
    openNewWindow (type = 'zalo') {
      let url = ''
      const name = ''
      switch (type) {
        case 'facebook':
          url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(process.env.NUXT_ENV_WEB_URL + this.link) + '&src=sdkpreparse'
          break
        case 'telegram':
          url = 'https://t.me/share/url?url=' + encodeURIComponent(process.env.NUXT_ENV_WEB_URL + this.link) + '&text=' + this.text
          break
        case 'twitter':
          url = 'https://twitter.com/intent/tweet?text=' + this.text + '&url=' + encodeURIComponent(process.env.NUXT_ENV_WEB_URL + this.link)
          break
        default:
          break
      }
      // console.log(url)
      window.open(url, name, 'height=570,width=520,resizable,scrollbars,status')
    }
  }
}
</script>
<style lang="scss">
  .list-share{
    > div {
      margin-right: 10px;
      color: #4086FF;
      &:last-child{
        margin-right: 0;
      }
      i{
        cursor: pointer;
        font-size: 1.5rem;
      }
    }
  }
</style>
