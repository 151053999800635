<template>
  <div class="form-search-data position-relative">
    <input :placeholder="placeholder" class="w-100" v-model="keyword" @keyup.enter="searchData" />
    <img src="~/mlearn/icons/home/search.svg" alt="" class="search pointer position-center" @click="searchData">
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: 'Tìm kiếm',
      required: false
    }
  },
  data () {
    return {
      keyword: this.value
    }
  },
  methods: {
    searchData () {
      this.keyword = this.keyword.trim()
      if (this.keyword.length > 100) {
        this.keyword = this.keyword.substring(0, 100)
      }
      this.$emit('enter', this.keyword)
    }
  }
}
</script>

<style lang="scss" scoped>
  .position-center{
    left: 90%;
    @media (max-width:1366px) {
      left: 94%;
    }
    @media (max-width:576px) {
      left: 93%;
    }
  }
  .form-search-data{
    @media (max-width:576px) {
      width: 100%;
    }
    input{
      background: var(--white);
      border: 1px solid #CED4DA !important;
      border-radius: 8px;
      padding:8px 40px 8px 16px;
      font-size: 16px;
      @media (max-width:576px) {
        padding:8px 20px 8px 10px;
        font-size: 14px;
      }
      &::placeholder{
        color: #848484;
      }
    }
    .search{
      right:16px;
      --size: 23px;
      width: var(--size);
      height: var(--size);
      @media (max-width:576px) {
        --size: 21px;
      }
    }
  }
</style>
