<template>
  <b-dropdown class="dropdown-select-class" :class="{'home-select': home}" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret :toggle-class="home ? 'text-left home-custom-caret home-custom-select text-truncate w-100' : 'text-left custom-caret custom-select text-truncate w-100'" variant="transparent">
    <template #button-content>
      {{ itemActive ? itemActive.name : placeholder }} <span v-if="show" class="require">*</span>
    </template>
    <b-dropdown-text v-if="all" @click="setItemActive({ name: 'Tất cả', id: '' })">Tất cả</b-dropdown-text>
    <b-dropdown-text v-for="item in limitJuniorClassCategory()" :key="item.id" @click="setItemActive(item)">{{ item.name }}</b-dropdown-text>
  </b-dropdown>
</template>

<script>
import {mapState} from 'vuex'
import Cookies from 'js-cookie'
import Resource from '~/common/api/resource'

const subjectResource = new Resource('category/subject')
export default {
  props: {
    all: {
      type: Boolean,
      default: true
    },
    require: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default () {
        return 'Chọn môn'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedId: {
      type: String,
      default () {
        return ''
      }
    },
    registerGuru: {
      type: Boolean,
      default () {
        return false
      }
    },
    libraryShow: {
      type: Boolean,
      default () {
        return false
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      listData: [],
      itemActive: null,
      show: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  watch: {
    selectedId () {
      this.itemActive = this.listData.find(it => it.id === this.selectedId)
    }
  },
  async created () {
    await this.loadData()
    this.itemActive = this.listData.find(it => it.id === this.selectedId)
    if (this.require && !this.itemActive) {
      this.show = true
    }
  },
  methods: {
    setItemActive (item) {
      this.$refs['dropdown-select-class'].hide(true)
      this.itemActive = item
      this.$emit('findCategory', item?.id || null)
    },
    limitJuniorClassCategory () {
      const classId = Cookies.get('interestedClass') || this.user?.class_id
      if (classId) {
        return [...this.listData].filter(it => it.list_class.some(c => c.id === classId))
      } else {
        return this.listData
      }
    },
    async loadData () {
      if (this.items.length) {
        this.listData = this.items
        return
      }
      const params = {}
      if (this.registerGuru) {
        params.register_guru = 1
      }
      if (this.libraryShow) {
        params.library_show = 1
      }
      this.listData = await subjectResource.list(params)
        .then(res => res.data)
    }
  }
}
</script>

<style lang="scss">
.dropdown-select-class {
  min-width: 130px;

  .dropdown-menu {
    .list-unstyled {
      padding: 0;
      .b-dropdown-text {
        padding: 10px 26px;
      }
    }
  }

  .dropdown-toggle {
    font-weight: 500;
    border: 1px solid #CED4DA !important;
    border-radius: 8px;
    box-shadow: none !important;
    padding: 10px 30px 10px 12px;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .require {
      color: var(--danger);
    }
  }

  ul {
    padding: 0;
    max-height: 300px;
    min-width: 100%;

    li {
      > .b-dropdown-text, > .dropdown-header {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;
        padding: 10px 16px;
        @media (max-width: 1366px) {
          padding: 8px 10px;
        }
      }

      .dropdown-header {
        // font-weight: bold;
        + ul {
          padding-left: 20px;
        }
      }

      &.active {
        background: transparent;
        color: #004390
      }

      .b-dropdown-text {
        &:hover {
          background: #F1F1F1;
          color: #004390
        }
      }

      &:last-child {
        .b-dropdown-text {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
