<template>
  <div>
    <div class="rank-block" :class="{'home': home}">
      <div class="rank-title text-uppercase d-flex align-items-center">
        <nuxt-link to="/bang-xep-hang">Bảng xếp hạng</nuxt-link>
      </div>
      <b-tabs content-class="mt-2" fill>
        <b-tab title="Top ngày" active class="rank-day" @click="getListBxh('RANK_IN_DAY')"></b-tab>
        <b-tab title="Top tuần" class="rank-week" @click="getListBxh('RANK_IN_WEEK')"></b-tab>
        <b-tab title="Top tháng" class="rank-month" @click="getListBxh('RANK_IN_MONTH')"></b-tab>
      </b-tabs>
      <div class="rank-info position-relative">
        <div class="rank-master form-data align-items-center" :class="{'home': home}" v-if="prevTop">
          <!--          <img src="~/mlearn/icons/home/rank-top.svg" alt="avatar" class="rank-icon"/>-->
          <span class="number-top font-weight-bold">{{ prevTop.rankIndex }}</span>
          <el-image class="avatar" :src="prevTop.avatar || NoAvatar" fit="cover"></el-image>
          <div class="content">
            <div class="user-name display-html display-1-line">{{ prevTop.fullName }}</div>
<!--            <div class="rank-time">(Top {{ prevTopText }})</div>-->
          </div>
          <span class="user-point">{{ prevTop.point }} điểm</span>
        </div>
        <div class="rank-list">
          <RankItem v-for="(item,ind) in listData" :key="ind" :ind="ind" :item="item" :home="home"/>
        </div>
        <div class="footer-rank position-absolute bottom-0 d-flex justify-content-between">
          <div class="col-6 d-flex align-items-center footer-group-left">
            <img src="~/mlearn/icons/home/surface1.svg" alt="">
            <nuxt-link to="/huong-dan-cong-diem" class="ml-1 pt-1">Chính sách thưởng</nuxt-link>
          </div>
<!--          <div class="col-6 d-flex align-items-center footer-group-right justify-content-end">-->
<!--            <img src="~/mlearn/icons/home/chart.svg" alt="">-->
<!--            <span class="ml-1 pt-1">Kết quả tháng 12</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div id="banner-right" class="banner-right mt-3" v-if="$store.state.banner.find(banner => banner.code === 'BANNER_RIGHT') && !home">
      <BannerItem type="BANNER_RIGHT"/>
    </div>
  </div>
</template>
<script type="text/javascript">
import {mapState} from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import BannerItem from '~/components/mlearn/banner/item.vue'
import RankItem from '~/components/mlearn/rank/item.vue'
import Resource from '~/common/api/resource'

const bxh = new Resource('score/rank')
export default {
  components: {
    BannerItem,
    RankItem
  },
  props: {
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      NoAvatar,
      listData: [],
      positionTopBannerRight: 0,
      positionTopFooter: 0,
      heightFooter: 0,
      heightBannerRight: 0,
      prevTop: null,
      prevTopText: ''
    }
  },
  computed: {
    prevDay () {
      return this.$moment().subtract(1, 'day').format('DD/MM/YYYY')
    },
    prevWeek () {
      return this.$moment().subtract(1, 'weeks').startOf('isoWeek')
    },
    prevMoth () {
      return this.$moment().subtract(1, 'months').endOf('month').format('MM/YYYY')
    },
    authenticated () {
      return this.$store.state.authenticated
    },
    bannerRight () {
      return this.$store.state.banner.find(banner => banner.code === 'BANNER_RIGHT')
    },
    ...mapState([
      'user'
    ])
  },
  created () {
    this.getListBxh()
  },
  methods: {
    getListBxh (type = 'RANK_IN_DAY') {
      this.listData = []
      const query = {
        rankType: type,
        pointType: 'DEFAULT',
        limit: 5
      }
      bxh.list(query)
        .then((response) => {
          if (response.data.length && response.status === 200) {
            if (this.authenticated) {
              this.prevTop = response.data[0]
              this.listData = response.data.slice(1).filter(b => b.userId !== this.prevTop.userId)
            } else {
              this.listData = response.data
            }
          } else {
            this.prevTop = null
            this.listData = []
          }
          // this.getPrevBxh(type)
        })
        .catch(() => {
        })
    },
    getPrevBxh (type = 'RANK_IN_DAY') {
      this.prevTop = null
      const query = {
        rankType: type,
        pointType: 'DEFAULT',
        limit: 1,
        date: this.prevDay
      }
      switch (type) {
        case 'RANK_IN_DAY':
          query.date = this.prevDay
          this.prevTopText = 'ngày ' + this.prevDay
          break
        case 'RANK_IN_WEEK':
          query.date = this.$moment(this.prevWeek).format('YYYY') + this.parseWeek(this.$moment(this.prevWeek).format('W'))
          this.prevTopText = `tuần ${this.$moment().isoWeek()}/${this.$moment().year()}`
          break
        default:
          query.date = this.prevMoth
          this.prevTopText = 'tháng ' + this.prevMoth
          break
      }
      bxh.list(query)
        .then((response) => {
          if (response.data.length && response.status === 200) {
            let bxhData = response.data
            if (this.authenticated) {
              this.prevTop = bxhData[0]
              bxhData = bxhData.slice(1).filter(b => b.userId !== this.prevTop.userId)
            }
            this.listData = bxhData
          } else {
            this.listData = []
            this.prevTop = null
          }
        })
        .catch(() => {
        })
    },
    scrollRightBar (event) {
      const currentScroll = window.$(window).scrollTop()
      // currentScroll >= this.positionTopBannerRight
      // check neu vi tri hien tai da di qua banner hay chua
      // currentScroll <= this.positionTopFooter - this.heightFooter - 85.5 - 50
      // 85.5 la css top position fixed
      // 50 la x2 padding 25px
      if (currentScroll >= this.positionTopBannerRight + 85.5 && currentScroll <= this.positionTopFooter - this.heightBannerRight - 85.5 - 50) {
        window.$('.banner-right').css({
          position: 'fixed',
          top: '85.5px'
        })
      } else if (currentScroll > this.positionTopFooter - this.heightBannerRight - 85.5 - 50) {
        window.$('.banner-right').css({
          position: 'absolute',
          top: this.positionTopFooter - this.heightBannerRight - 50
        })
      } else if (currentScroll < this.positionTopBannerRight + 85.5) {
        window.$('.banner-right').css({
          position: 'static'
        })
      }
    },
    redirectAdvertise (route) {
      window.open(route, '_blank')
    },
    showQuestion () {
      this.$store.dispatch('setShowQuestionDialog', true)
    },
    parseWeek (week) {
      return parseInt(week) < 10 ? '0' + week : week
    }
  }
}
</script>
<style lang="scss">
.rank-block {
  padding-bottom: 24px;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);

  &.home {
    //background: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .rank-title {
    font-weight: bold;
    //color: var(--white);
    //background: #FCB431;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    padding: 16px 18px 12px;
    @media (max-width: 1366px) {
      padding: 10px 12px;
      font-size: 15px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      padding: 10px 12px;
    }
  }

  .tabs {
    padding: 0 12px;
    @media (max-width: 1366px) {
      padding: 0 8px;
    }
    @media (max-width: 576px) {
      padding: 0 10px;
    }

    .nav-tabs {
      border-bottom: 1px solid #EBEBEB;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      .nav-item {
        .nav-link {
          text-align: center;
          color: #6B6D70;
          font-size: 14px;
          @media (max-width: 1366px) {
            font-size: 13px;
            padding-left: 0;
            padding-right: 0;
          }

          &.active {
            background-color: unset;
            border-color: transparent;
            border-bottom: 1px solid #EBEBEB;
            font-weight: bold;
            color: #004390;
            position: relative;

            &::before {
              position: absolute;
              content: '';
              height: 2px;
              width: 30px;
              margin: auto;
              background: #004390;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }

  .rank-info {
    min-height: 160px;
    padding: 0 16px 45px 16px;
    @media (max-width: 1366px) {
      min-height: 200px;
      padding: 0 10px 40px 10px;
    }

    .rank-master {
      background: var(--white);
      box-shadow: 0px 2px 8px rgba(116, 149, 211, 0.37);
      border-radius: 4px;
      padding: 10px;
      @media (max-width: 1366px) {
        padding: 8px;
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 576px) {
      }

      .rank-icon {
        margin-right: 10px;
        @media (max-width: 1366px) {
          margin-right: 5px;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 576px) {
        }
      }

      .number-top {
        color: #576988;
        margin-right: 40px;
        @media (max-width: 1366px) {
          margin-right: 15px;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 576px) {
        }
      }

      .avatar {
        border-radius: 50%;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        @media (max-width: 1366px) {
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 576px) {
          width: 36px;
          height: 36px;
        }
      }

      .content {
        .user-name {
          font-weight: 500;
          font-size: 16px;
          @media (max-width: 1366px) {
            font-size: 12px;
          }
        }

        .rank-time {
          font-size: 12px;
          color: #868789;
          @media (max-width: 1366px) {
            font-size: 10px;
          }
        }
      }

      .user-point {
        font-weight: 500;
        color: #FFA216;
        font-size: 16px;
        @media (max-width: 1366px) {
          font-size: 13px;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 576px) {
        }
      }
    }

    .rank-list {
      .rank-item {
        border-bottom: 1px solid #EBEBEB;
        //&:last-child{
        //  border-bottom: 0;
        //}
      }
    }

    .footer-rank {
      left:10px;
      right:10px;
      .footer-group-right {
        //padding-right: 2rem;

        p {
          @media (max-width: 1366px) {
            font-size: 13px;
          }
          @media (max-width: 992px) {
          }
          @media (max-width: 576px) {
          }
        }

        @media (max-width: 1366px) {
          font-size: 13px;
          //padding-right: 1rem;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 576px) {
        }
      }

      .footer-group-left {
        p {
          @media (max-width: 1366px) {
            font-size: 13px;
          }
        }

        @media (max-width: 1366px) {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
