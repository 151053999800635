<template>
  <span class="custom-arrow rounded-circle d-flex align-items-center justify-content-center" v-html="require('~/assets/mlearn/icons/slider/right.svg?raw')"></span>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
