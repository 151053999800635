<template>
  <b-dropdown class="dropdown-select-class" :class="{'home-select': home}" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret :toggle-class="home ? 'text-left home-custom-caret home-custom-select text-truncate w-100' : 'text-left custom-caret custom-select text-truncate w-100'" variant="transparent">
    <template #button-content>
      {{itemActive ? itemActive.name : placeholder}}
    </template>
    <b-dropdown-text @click="setItemActive({ name: 'Tất cả', value: 0 })">Tất cả</b-dropdown-text>
    <b-dropdown-group header="Chưa trả lời" header-tag="div">
      <b-dropdown-text @click="setItemActive({ name: 'Mới nhất', value: 'desc' })">Mới nhất</b-dropdown-text>
      <b-dropdown-text @click="setItemActive({ name: 'Cũ nhất', value: 'asc' })">Cũ nhất</b-dropdown-text>
    </b-dropdown-group>
    <b-dropdown-text @click="setItemActive({ name: 'Đã trả lời', value: 1 })">Đã trả lời</b-dropdown-text>
    <b-dropdown-text @click="setItemActive({ name: 'Hỏi lần đầu', value: 4 })">Hỏi lần đầu</b-dropdown-text>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default () {
        return 'Trạng thái câu hỏi'
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      itemActive: null,
      statusValue: '',
      arrItemActive: [
        { name: 'Tất cả', value: 0 },
        { name: 'Mới nhất', value: 'desc' },
        { name: 'Cũ nhất', value: 'asc' },
        { name: 'Đã trả lời', value: 1 },
        { name: 'Hỏi lần đầu', value: 4 }
      ]
    }
  },
  created () {
  },
  mounted () {
    if (!this.$store.state.storeRedirect) {
      console.log('false', this.$store.state.storeRedirect)
      this.setStore(this.statusValue)
    } else {
      console.log('true')
      this.statusValue = this.$store.state.storeRedirect.status_value
    }
    // this.setStore(this.statusValue)
    // console.log('status', this.$store.state.storeRedirect.status_value)
    this.itemActive = this.arrItemActive.find(it => it.value === this.statusValue)
  },
  methods: {
    setItemActive (item) {
      this.$refs['dropdown-select-class'].hide(true)
      this.itemActive = item
      this.$emit('findStatus', item?.value || 0)
      this.setStore(item?.value)
    },
    setStore (value) {
      const data = { ...this.$store.state.storeRedirect, status_value: value }
      this.$store.dispatch('setStoreRedirect', data)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
