<template>
  <div class="playlist-notify" :style="{'opacity': flagNotify ? 1 : 0}" v-html="textNotify"></div>
</template>

<script>
export default {
  props: {
    textNotify: {
      type: String,
      default: '',
      required: false
    },
    flagNotify: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      myTimeout: null
    }
  },
  watch: {
    flagNotify () {
      if (this.myTimeout) {
        clearTimeout(this.myTimeout)
      }
      if (this.flagNotify) {
        this.myTimeout = setTimeout(() => {
          this.hide()
        }, 3000)
      }
    }
  },
  methods: {
    hide () {
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
  .playlist-notify{
    position: fixed;
    top: 90px;
    right: 32px;
    max-width: 250px;
    word-break: break-word !important;
    word-wrap: break-word !important;
    padding: 15px 14px 14px 16px;
    background: var(--white);
    box-shadow: 0px 4px 8px rgba(54, 99, 225, 0.21);
    border-radius: 4px;
    font-size: 16px;
    z-index: 9999;
    transition: opacity 1s;
  }
</style>
