<template>
  <div v-if="item" class="item-question">
    <div class="user-info form-data">
      <b-avatar :src="item.owner.avatar || NoAvatar" class="avatar"></b-avatar>
      <div class="content">
        <div class="user-name text-break">{{item.owner.fullName}}</div>
        <div class="time-ago">{{item.created_at | ralativeTime}}</div>
        <div class="class-info">{{ item.class.name }} &#8226; {{ item.category.name }}</div>
      </div>
    </div>
    <div>
      <div class="question-content">
        <nuxt-link :to="'/cau-hoi/' + (item.type === 1 ? item.id : item.question_id) ">
          <vue-mathjax v-if="showMore" :formula="item.content" :safe="false"></vue-mathjax>
          <vue-mathjax v-else :formula="item.is_formula ? item.content : $helper.getSubString(item.content, limit)" :safe="false"></vue-mathjax>
        </nuxt-link>
        <div class="show-more text-primary" v-if="!showMore && !item.is_formula"><span @click="showMore = true">Xem thêm</span></div>
      </div>
      <div v-if="item.image && item.image.length" class="question-image">
        <viewer v-if="item.image.length" :images="item.image" :options="options" class="image-container">
          <img v-for="img in item.image" :src="img" :key="img">
        </viewer>
      </div>
    </div>
  </div>
</template>

<script>
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      NoAvatar,
      showMore: true,
      limit: 500,
      options: {
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          rotateRight: true,
          rotateLeft: true
        }
      }
    }
  },
  created () {
    if (this.item.content.length > this.limit) {
      this.showMore = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-question{
    .user-info{
      .avatar{
        width:62px;
        height:62px;
        margin-right: 12px;
        border-radius: 50%;
        object-fit: cover;
      }
      .content{
        .user-name{
          font-weight: bold;
          font-size: 16px;
        }
        .time-ago{
          font-size: 12px;
          line-height: 16px;
          color: #65676B;
        }
        .class-info{
          font-weight: 500;
          font-size: 14px;
          color: #004390;
        }
      }
    }
    .question-content{
      padding:20px 0 16px;
      font-size: 16px;
      text-align: justify;
      .content-more{
        color: #004390;
        z-index:99;
      }
    }
    .question-image{
      .image-container{
        background: #E2EBF6;
        border-radius: 4px;
        img{
          max-width: 100%;
          max-height: 300px;
        }
      }
    }
  }
</style>
